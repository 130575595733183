import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  LinearProgress,
} from "@mui/material";
// import emailjs from "emailjs-com";
// import axios from "axios";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ThankYouPage from "./thankYouPage";

function App() {
  const questions = [
    { label: "Full Name", name: "fullName" },
    { label: "Company Name", name: "companyName" },
    { label: "Email Address", name: "emailAddress" },
    { label: "Phone Number", name: "phoneNumber" },
    { label: "Primary IT Concern", name: "itNeeds" },
    { label: "Number Of Employees", name: "employees" },
    { label: "Current IT Systems", name: "currentITSystems" },
    { label: "Preferred Assessment Date", name: "preferredDate", type: "date" },
    { label: "Preferred Time", name: "preferredTime", type: "time" },
  ];
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [step, setStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [touched, setTouched] = useState({});
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    emailAddress: "",
    phoneNumber: "",
    itNeeds: "",
    employees: "",
    currentITSystems: "",
    preferredDate: "",
    preferredTime: "",
  });

  // const handleNext = () =>{
  //   setStep((prevStep) => Math.min(prevStep + 1, questions.length - 1));
  // }

  const handleNext = () => {
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0 && !showError) {
      setStep((prevStep) => Math.min(prevStep + 1, questions.length - 1));
    } else {
      const currentErrorField = Object.keys(formErrors)[0];
      setTouched((prevTouched) => ({
        ...prevTouched,
        [currentErrorField]: true,
      }));
      setStep((prevStep) => Math.min(prevStep + 1, questions.length - 1));
    }
  };

  const handlePrevious = () => setStep((prevStep) => Math.max(prevStep - 1, 0));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({});
  };

  const validate = () => {
    let formErrors = {};

    if (
      !formData.fullName.trim() &&
      (!touched.fullName || touched.fullName === false)
    ) {
      formErrors.fullName = "Name is required";
    }
    if (
      !formData.companyName.trim() &&
      (!touched.companyName || touched.companyName === false)
    ) {
      formErrors.companyName = "Company Name is required";
    }
    if (
      !formData.emailAddress.trim() &&
      (!touched.emailAddress || touched.emailAddress === false)
    ) {
      formErrors.emailAddress = "Email is required";
    } else if (
      formData.emailAddress.trim() &&
      !/\S+@\S+\.\S+/.test(formData.emailAddress)
    ) {
      formErrors.emailAddress = "Invalid email address";
    }
    if (
      !formData.phoneNumber.trim() &&
      (!touched.phoneNumber || touched.phoneNumber === false)
    ) {
      formErrors.phoneNumber = "Phone number is required";
    }
    if (
      !formData.itNeeds.trim() &&
      (!touched.itNeeds || touched.itNeeds === false)
    ) {
      formErrors.itNeeds = "Primary IT concern is required";
    }
    if (
      !formData.employees.trim() &&
      (!touched.employees || touched.employees === false)
    ) {
      formErrors.employees = "Number of employees is required";
    }
    if (
      !formData.currentITSystems.trim() &&
      (!touched.currentITSystems || touched.currentITSystems === false)
    ) {
      formErrors.currentITSystems = "Current IT system is required";
    }
    if (formData.preferredDate === "" && step === 6) {
      // formErrors.preferredDate = "Preferred Date is required";
      setShowError(true);
    }
    if (formData.preferredTime === "" && step === 7) {
      // formErrors.preferredTime = "Preferred Time is required";
      setShowError(true);
    } else {
      setShowError(false);
    }

    setErrors(formErrors);
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      e.preventDefault();
      const validationErrors = validate();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        alert("⚠️🚨Please fill all the required fields!! 🚨⚠️");
      } else {
        setErrors({});
        setShowError(false);
        const response = await fetch(
          `https://cms.seqnetworks.com/api/event-assessments`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...formData,
            }),
          }
        );
        console.log("RESPONSE", response);
        if (response.ok) {
          // alert("You have completed the assessment 👍");
          setIsSubmitted(true);
          setFormData({
            fullName: "",
            companyName: "",
            emailAddress: "",
            phoneNumber: "",
            itNeeds: "",
            employees: "",
            currentITSystems: "",
            preferredDate: "",
            preferredTime: "",
          });
        } else {
          // Error sending email
          alert("Error occured:", response && response.status);
        }
      }
    } catch (error) {
      console.error("Error in creating:", error);
      alert("An unexpected error occurred. Please try again later.");
    }
  };

  // const handleSubmit = async () => {
  //   const emailParams = {
  //     ...formData,
  //   };

  //   emailjs
  //     .send(
  //       "service_9ekj01k", // Replace with your EmailJS service ID
  //       "template_bonv3mr", // Replace with your EmailJS template ID
  //       emailParams,
  //       "ibE2uQiFJb932D7Jy" // Replace with your EmailJS user ID
  //     )
  //     .then(
  //       (response) => {
  //         console.log("SUCCESS!", response.status, response.text);
  //         alert("Form submitted and email sent!");
  //       },
  //       (error) => {
  //         console.log("FAILED...", error);
  //         alert("Failed to send email.");
  //       }
  //     );
  // };

  return isSubmitted ? (
    <ThankYouPage />
  ) : (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "2rem" }}>
      <Typography variant="subtitle1" color="textSecondary">
        Question {step + 1} of {questions.length}
      </Typography>

      <Typography variant="h5" style={{ margin: "1rem 0" }}>
        {questions[step].label}
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {questions[step].type === "date" ? (
          <DatePicker
            sx={{ minWidth: "100%", marginBottom: "2rem" }}
            disablePast={true}
            label={`${questions[step].label} *`}
            value={
              formData[questions[step].name]
                ? new Date(formData[questions[step].name])
                : null
            }
            onChange={(newValue) => {
              setFormData({
                ...formData,
                [questions[step].name]: newValue
                  ? newValue.toISOString().split("T")[0]
                  : ""
              });
                setShowError(false);
            }}
            // renderInput={(params) => (
            //     <TextField
            //       {...params}
            //       fullWidth
            //       variant="outlined"
            //       error={!!errors[questions[step]?.name]}
            //       helperText={errors[questions[step]?.name]}
            //     />
            //   )}
            textField={{
              fullWidth: true,
              variant: "outlined",
              error: !!errors[questions[step]?.name],
              helperText: errors[questions[step]?.name],
            }}
          />
        ) : questions[step].type === "time" ? (
          <TimePicker
            sx={{ minWidth: "100%", marginBottom: "2rem" }}
            label={`${questions[step].label} *`}
            value={
              formData[questions[step].name]
                ? new Date(`1970-01-01T${formData[questions[step].name]}`)
                : null
            }
            onChange={(newValue) => {
              setFormData({
                ...formData,
                [questions[step].name]: newValue
                  ? newValue.toISOString().split("T")[1].slice(0, 5) // Save time as "HH:mm"
                  : ""
              });
                setShowError(false);
            }}
            textField={{
              fullWidth: true,
              variant: "outlined",
              error: !!errors[questions[step]?.name],
              helperText: errors[questions[step]?.name],
            }}
            // renderInput={(params) => (
            //   <>
            //     <TextField
            //       {...params}
            //       fullWidth
            //       variant="outlined"
            //       error={!!errors[questions[step]?.name]}
            //       helperText={errors[questions[step]?.name]}
            //     />
            //   </>
            // )}
          />
        ) : (
          <TextField
            fullWidth
            variant="outlined"
            label={questions[step]?.label}
            name={questions[step]?.name}
            value={formData[questions[step]?.name]}
            onChange={handleChange}
            error={!!errors[questions[step]?.name]}
            helperText={errors[questions[step]?.name] || ""}
            type={questions[step]?.type || "text"}
            style={{ marginBottom: "2rem" }}
            required
          />
        )}
      </LocalizationProvider>
      {showError && (
        <span style={{ color: "red" }}>
          Preferred Date and time is required
        </span>
      )}
      <Box display="flex" justifyContent="space-between" width="100%">
        <Button
          variant="contained"
          color="primary"
          onClick={handlePrevious}
          disabled={step === 0}
        >
          Previous
        </Button>

        {/* {step === questions.length - 1 ? (
          <Button variant="contained" color="secondary" onClick={handleSubmit}>
            Submit
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        )} */}
        {step === questions.length - 1 ? (
          <Button variant="contained" color="secondary" onClick={handleSubmit}>
            Submit
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={Object.keys(errors).length > 0}
          >
            Next
          </Button>
        )}
      </Box>

      <Box marginTop={4} width="100%">
        <LinearProgress
          variant="determinate"
          value={(step / (questions.length - 1)) * 100}
        />
        <Typography variant="caption" display="block">
          {Math.round((step / (questions.length - 1)) * 100)}% Complete
        </Typography>
      </Box>
    </Container>
  );
}

export default App;
